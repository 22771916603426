import axios from 'axios'
import EnvProvider from "@tt/vue-environment/EnvProvider";

export default function createClient(baseUrl) {
  const barcode = window.sessionStorage.getItem("barcode")
  const credentials = window.sessionStorage.getItem("credentials") ?? EnvProvider.value("GUEST_USER")+":"+EnvProvider.value("GUEST_PASSWORD")
  const apiClient = axios.create({
    baseURL: baseUrl,
    headers: {
      'Accept-Language': localStorage.locale ?? "en",
      'x-entity-user': credentials,
      'x-entity-user-barcode': barcode
    }
  });

  apiClient.interceptors.response.use(
      function (response) {
        if (response.data) {
          if (Array.isArray(response.data['hydra:member']) === true) {
            let data = {
              items: [],
              totalItems: 0
            }

            data.items = response.data['hydra:member'];
            data.totalItems = response.data['hydra:totalItems'];
            return data;
          } else {
            return response.data;
          }
        }
        return response;
      },
      function (error) {
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 401) {
            return Promise.reject(error.response.data);
          }

          if (error.response.status === 422 && error.response.data) {
            return Promise.reject(error.response.data);
          }

          if (error.response.status === 405) {
            let err = {};
            err["hydra:description"] = error.response.data.detail;
            return Promise.reject(err);
          }

          return Promise.reject(error.response.data);
        }
      }
  )

  return apiClient;
}

