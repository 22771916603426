import Vue from "vue";
import Vuex from "vuex";
import config from "@/store/modules/config.js";
import products from "@/store/modules/products.js";
import announceOrder from "@/store/modules/announceOrder.js";
import pickUpOrder from "@/store/modules/pickUpOrder.js";
import strategy from "@/store/modules/strategy.js";
import locationsList from "@/store/modules/locationsList.js";
import shipping from "@/store/modules/shipping.js";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    config,
    products,
    strategy,
    announceOrder,
    pickUpOrder,
    locationsList,
    shipping
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: debug ? [Vuex.createLogger()] : []
});

export default store;
