import apiServiceHelpers from "./apiServiceHelpers";
const baseApiService = (apiClient, resource) => {
  return {
    list: (params, options = null) => apiClient.get(`${resource}` + apiServiceHelpers.buildQueryParams(params), options),
    // Get a single resource by its id
    get: (id, params, options = null) => apiClient.get(`${resource}/${id}` + apiServiceHelpers.buildQueryParams(params), options),
    // Get subresource(s) by id
    // If you require a more complex subresource url you can pass in other path elements in the subResource string.
    getSubResource: (id, subResource, params, options = null) => apiClient.get(`${resource}/${id}/${subResource}` + apiServiceHelpers.buildQueryParams(params), options),
    // Alternate version: some subresources have the identifier at the end
    getSubResourceAlt: (subResource, id, params, options = null) => apiClient.get(`${resource}/${subResource}/${id}` + apiServiceHelpers.buildQueryParams(params), options),
    // Create a new resource
    create: (payload, options = null) => apiClient.post(`${resource}`, payload, options),
    // Replace an existing resource with payload
    update: (id, payload, options = null) => {
      const clientOptions = { ...options, ...{ headers: { "Content-Type": "application/ld+json" } } };
      return apiClient.put(`${resource}/${id}`, payload, clientOptions)
    },
    // Merge new payload into a resource
    patch: (id, payload, options = null) => {
      const clientOptions = { ...options, ...{ headers: { "Content-Type": "application/merge-patch+json" } } };
      return apiClient.patch(`${resource}/${id}`, payload, clientOptions)
    },
    // Remove a resource by its id
    delete: (id) => apiClient.delete(`${resource}/${id}`)
  }
}
export default baseApiService;
