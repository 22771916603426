import shipmentApiService from "@/services/shipmentApiService";
import cancelling from "@/store/modules/shipping/cancelling.js"
import picking from "@/store/modules/shipping/picking.js"
import packing from "@/store/modules/shipping/packing.js"


const shipping = {
  namespaced: true,
  modules: {
    packing,
    picking,
    cancelling
  },
  state: {
    items: [],
    item: null,
    total: 0,
    pigeonhole: null,
    loading: false,
    statistics: {
      totalItemsToShip: 0,
      totalItemsToCancel: 0,
      totalItemsToPick: 0,
      totalItemsToPack: 0
    }
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data;
    },
    SET_TOTAL(state, data) {
      state.total = data;
    },
    SET_ITEM(state, data) {
      state.item = data;
    },
    SET_PIGEONHOLE(state, data) {
      state.pigeonhole = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_STATISTICS(state, statistics) {
      state.statistics = statistics;
    }
  },
  actions: {
    fetchStatistics(context, refresh = true) {
      if (refresh || context.state.statistics.length === 0) {
        const params = { search: [{ column: "locationCode", value: window.sessionStorage.location }] }
        shipmentApiService.shipments.statistics(params)
            .then(response => {
              context.commit("SET_STATISTICS", response);
            })
            .catch((err) => {
              console.log(err);
            })
      }
    },
    fetchItems(context, refresh = true) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = {
          search: [
            { column: "completeStatus", value: 'fully_packed' },
            { column: "locationCode", value: window.sessionStorage.location }
          ],
        }
        shipmentApiService.shipments.list(params)
            .then(response => {
                  context.commit("SET_TOTAL", response.totalItems)
                  context.commit("SET_ITEMS", response.items);
                  context.commit("SET_LOADING", false);
                },
            )
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              context.commit("SET_LOADING", false);
            });
      }
    },
    setItem(context, shipment) {
      context.commit("SET_ITEM", shipment)
    },
    setPigeonhole(context, pigeonhole) {
      context.commit("SET_PIGEONHOLE", pigeonhole)
    },
    shipAll(context, {shipment}) {
      context.commit("SET_LOADING", true);
      shipmentApiService.shipments.shipAll({shipment: shipment}).then(
          response => {
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
    }
  }
}

export default shipping;