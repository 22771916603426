import axios from "axios";
import EnvProvider from "@tt/vue-environment/EnvProvider";

const strategy = {
  namespaced: true,
  modules: {},
  state: {
    strategyItems: [],
    loading: false,
    error: false
  },
  mutations: {
    SET_ITEMS(state, items) {
      state.strategyItems = items;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    getStrategies(context, refresh = true) {
      if (refresh || context.state.strategyItems.length === 0) {
        context.commit("SET_LOADING", true);
        context.state.strategyItems = [];
        axios({
          method: "get",
          url:
            EnvProvider.value("STORE_ALLOCATION") +
            "tickets_count_to_allocate" +
            "?locationCode=" +
            window.sessionStorage.getItem("location"),
          headers: {
            Accept: "application/json",
            'x-entity-user': window.sessionStorage.getItem("credentials"),
            'x-entity-user-barcode': window.sessionStorage.getItem("barcode")
          }
        }).then(
            (response) => {
            context.commit("SET_ITEMS", response.data);
            context.commit("SET_LOADING", false);
          },
          err => {
            context.commit("SET_LOADING", false);
            console.log(err);
          }
        );
      }
    }
  }
};

export default strategy;
