import shipmentApiService from "@/services/shipmentApiService";

const picking  = {
  namespaced: true,
  state: {
    items: [],
    total: 0,
    loading: false,
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data;
    },
    SET_TOTAL(state, data) {
      state.total = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    }
  },
  actions: {
    fetchItems(context, refresh = true) {
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = {
          search: [
            { column: "qtyPicked", value: 0 }
          ]
        }
        shipmentApiService.shipmentItems.list(params)
          .then(response => {
            context.commit("SET_ITEMS", response.items)
            context.commit("SET_TOTAL", response.totalItems);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            context.commit("SET_LOADING", false);
          });
      }
    },
  }
}

export default picking;