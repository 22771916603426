import shipmentApiService from "@/services/shipmentApiService";

const cancelling  = {
  namespaced: true,
  state: {
    items: [],
    item: null,
    total: 0,
    pigeonhole: null,
    loading: false,
    error: null,
  },
  mutations: {
    SET_ITEMS(state, data) {
      state.items = data;
    },
    SET_TOTAL(state, data) {
      state.total = data;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  actions: {
    fetchItems(context, refresh = true) {
      context.commit("SET_ERROR", null)
      if (refresh || context.state.items.length === 0) {
        context.commit("SET_LOADING", true);
        const params = {
          search: [
            { column: "toCancel", value: true },
            { column: "locationCode", value: window.sessionStorage.location }
          ],
          pagination: {
            page: 1,
            itemsPerPage: 1
          }
        }
        shipmentApiService.shipmentItems.list(params)
            .then(response => {
              context.commit("SET_TOTAL", response.totalItems)
              context.commit("SET_ITEMS", response.items)
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              context.commit("SET_LOADING", false)
            });
      }
    },
    async cancel(context, data) {
      context.commit("SET_LOADING", true)
      await shipmentApiService.shipmentItems.cancel({
        pigeonhole: data.pigeonhole,
        locationCode: window.sessionStorage.location,
        barcode: data.barcode
      }).then(response => {
      }).catch(error => {
        context.commit("SET_ERROR", error['hydra:description'])
      }).finally(() => {
        context.commit("SET_LOADING", false)
      });
    }
  },
  getters: {
    firstItem(state) {
      return state.items[0]
    }
  }
}

export default cancelling;