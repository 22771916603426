import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import 'vuetify/dist/vuetify.min.css'
import '@/assets/styles/style.css';
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);
export default new Vuetify({
  options: { customProperties: true },
  theme: {
    light: true,
    themes: {
      light: {
        primary: colors.shades.white
      }
    }
  },
});
