const apiServiceHelpers = {
  buildSortQueryParams: (urlParams, sorting = []) => {
    if (sorting.sortBy.length === sorting.sortDesc.length) {
      for (let i = 0; i < sorting.sortBy.length; i++) {
        urlParams.append("order[" + sorting.sortBy[i] + "]", ((sorting.sortDesc[i]) ? 'desc' : 'asc'));
      }
    }
    return urlParams;
  },
  buildPaginationParams: (urlParams, pagination = null) => {
    if (pagination !== null) {
      urlParams.append("page", (pagination.page > 1 ? pagination.page : "1"));
      urlParams.append("itemsPerPage", pagination.itemsPerPage);
    }
    return urlParams;
  },
  buildSearchParams: (urlParams, search = []) => {
    for (let i = 0; i < search.length; i++) {
      urlParams.append(search[i].column, search[i].value);
    }
  },
  buildQueryParams(config = null) {
    if (config === null) {
      return '';
    }

    let urlParams = new URLSearchParams();
    let { sorting, pagination, search } = config;

    if (sorting) {
      this.buildSortQueryParams(urlParams, sorting);
    }

    if (pagination) {
      this.buildPaginationParams(urlParams, pagination);
    }

    if (search && search.length > 0) {
      this.buildSearchParams(urlParams, search)
    }

    if (decodeURI(urlParams.toString()).length) {
      return "?" + decodeURI(urlParams.toString());
    }
  }
}

export default apiServiceHelpers;
